<template>
  <div>
  </div>
</template>

<script>
import { loadTossPayments } from '@tosspayments/sdk'
export default {
  name: 'TossPayment',
  computed: {
    hasQueryParams () {
      return this.$route.query.amount &&
             this.$route.query.orderId &&
             this.$route.query.orderName &&
             this.$route.query.customerName
    }
  },
  methods: {
    initTossPayment () {
      if (this.hasQueryParams) {
        loadTossPayments(`${process.env.VUE_APP_DRTUNES_TOSS_PAYMENT_CLIENT_KEY}`).then(tossPayments => {
          tossPayments.requestPayment('CARD', {
            amount: this.$route.query.amount,
            orderId: this.$route.query.orderId,
            orderName: this.$route.query.orderName,
            customerName: this.$route.query.customerName,
            successUrl: `${window.location.origin}/payments/success?pg=toss`,
            failUrl: `${window.location.origin}/payments/fail`
          }).catch((error) => {
            console.error(error)
          })
        }).catch((error) => {
          console.error('error: ', error)
        })
      } else {
        this.$alert('결제가 정상적으로 처리되지 않았습니다.', {
          type: 'warning'
        }).then(() => {
          this.$router.push({name: 'PaymentFail', query: {orderId: this.$route.query.orderId}})
        })
      }
    }
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    this.initTossPayment()
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>
